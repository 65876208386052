import { useGetPartnersInfoWrapper } from 'src/Utils/Hooks/GetPartnersInfo/GetPartners/useGetPartnersInfoWrapper';
import { useState } from 'react';
import ButtonWithIcon from 'src/SharedComponents/ButtonWithIcon/ButtonWithIcon';
import CreatePartner from './CreatePartner/CreatePartner';
import PartnersInfo from './PartnersInfo/PartnersInfo';
const Partners: React.FC = () => {
    const { data, getPartnersInfo } = useGetPartnersInfoWrapper()
    const [showCreatePartnerDialog, setShowCreatePartnerDialog] = useState<boolean>(false);

    return (
        <>
            <div className="col-auto d-flex justify-content-end">
                <ButtonWithIcon
                    backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                    onClick={() => { setShowCreatePartnerDialog(true) }}
                    label='New Partner'
                    icon='bi bi-plus-lg'
                    testId='newUser'
                    color='white'
                />
            </div>
            {showCreatePartnerDialog &&
                <CreatePartner
                    onClose={() => setShowCreatePartnerDialog(false)}
                    updatePartners={() => { getPartnersInfo() }} />
            }
            {data &&
                <div className='row'>
                     <div className='mt-5'>
                        <PartnersInfo
                            partnerInfo={data}
                        />
                    </div>
                </div>
            }
        </>
    )
}
export default Partners;