import { useCallback, useEffect, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useGetRequest } from "../../ApiWrappers/useGetRequest";
export interface IPartnerInfo  {
    distributorId:string;
    name:string;
    email?:string;
    contactNumber?:string;
    streetAddress?:String;
    city?:string;
    country?:String;
    zipCode?:string;
    isKol?:string;
}
export const useGetPartnersInfo = () =>{
  const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);
  const scope = Config.GetConfigData().auraWebService?.scopes[0];
  const requestUrl = `${baseUrl}api/v1/distributor`;
  const [getDetails, data, loading, error] = useGetRequest<IPartnerInfo[]>()
  useEffect(() => {
    getDetails(scope, requestUrl)
 },[getDetails, baseUrl, scope, requestUrl])

 const getPartnersInfo = useCallback(() => {
  getDetails(scope, requestUrl) 
 },[getDetails, scope,  requestUrl])
    return { 
      getPartnersInfo,
      data,
      loading,
      error
   };
}