import InputBox from "../InputBox/InputBox";
import './InputBoxWithLabel.css'

export interface IInputBoxWithLabel {
  label: string;
  width?: string;
  height?: string;
  optional?: boolean;
  value?: string;
  readOnly?: boolean;
  onChange: (text: string) => void;
}
const InputBoxWithLabel: React.FC<IInputBoxWithLabel> = ({ label, width, height, optional, value, readOnly, onChange }) => {
  return (
    <div>
      <div className="label mb-2">
        {label}
        {optional && <span className='optional'>Optional</span>}
      </div>
      <InputBox width={width} height={height} value={value} onChange={onChange} readOnly={readOnly}/>
    </div>
  )
}
export default InputBoxWithLabel;