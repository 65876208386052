import { useEffect } from "react"
import { useAuraSupportInfo } from "../../UseAuraInfoContext"
import { useInvitePartner } from "./useInvitePartnerUser"

export const useInvitePartnerUserWrapper = () => {
    const {invitePartnerUser, reqestResponse, reqestLoading, reqestError } = useInvitePartner()
    const {setLoader} = useAuraSupportInfo()
    useEffect(() => {
        setLoader(reqestLoading)
     },[reqestLoading, setLoader])
    return {invitePartnerUser, reqestResponse, reqestError}
}