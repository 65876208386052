import { useAuraSupportInfo } from "src/Utils/Hooks/UseAuraInfoContext";
import NavBar from "../Navbar/NavBar";
import InfoBar, { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";

const NavbarInfoContainer:React.FC = () => {
    const { show, setShow, infoMessageDetails } = useAuraSupportInfo();
    return(
        <>
            <div className="row mb-2">
               <NavBar/>
            </div>
            {show && infoMessageDetails.messageType !== InfoMessageType.none &&
            <div className="row">
               <InfoBar Message={infoMessageDetails.message} MessageType={infoMessageDetails.messageType} closeClick={() => {setShow(false)}} />
            </div>
        }
        </>
    )
}
export default NavbarInfoContainer;