import { useEffect, useRef } from "react";
import { useAuraSupportInfo } from "../UseAuraInfoContext";
import { useCreatePartnerWrapper } from "./CreatePartner/useCreatePartnerWrapper"
import { useInvitePartnerUserWrapper } from "./InvitePartnerUser/useInvitePartnerUserWrapper";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";

export const useCreatePartnerAndInvitePartnerUser = () => {
    const {partnerInfo, createNewPartner, createPartnerError} = useCreatePartnerWrapper()
    const {invitePartnerUser, reqestResponse, reqestError} = useInvitePartnerUserWrapper();
    const {reset, setInfobar} = useAuraSupportInfo()
    const partnerUserEmail = useRef('');
    const partnerName = useRef('');

    const createPartnerAndInviteUser = (name:string, userEmail:string) => {
        reset();
        partnerUserEmail.current = userEmail;
        partnerName.current = name
        createNewPartner(name)
    }
    useEffect(() => {
        if(partnerInfo && partnerInfo.distributorId) {
            invitePartnerUser(partnerUserEmail.current, partnerInfo.distributorId)
        }
    }, [partnerInfo, invitePartnerUser])

    useEffect(() => {
      if(createPartnerError !== '') {
        let message = `Failed to create partner : ${partnerName.current}`
        logger.logError(createPartnerError)
        setInfobar({message: message, messageType: InfoMessageType.error });
      }
    },[createPartnerError, setInfobar])

    useEffect(() => {
        if(reqestError !== '') {
          let message = `Partner ${partnerName.current} is created. ${reqestError} `
          logger.logError(reqestError)
          setInfobar({message: message, messageType: InfoMessageType.error });
        }
    },[reqestError, setInfobar])

    useEffect(() => {
        if(reqestResponse) {
          let message = `Partner ${partnerName.current} is created successfully `
          logger.logInformation(message)
          setInfobar({message: message, messageType: InfoMessageType.success });
        }
    }, [reqestResponse, setInfobar])

    return {createPartnerAndInviteUser, reqestResponse}
}