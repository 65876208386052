import { useAuraSupportInfo } from 'src/Utils/Hooks/UseAuraInfoContext';
import './NavbarContentWrapper.css'
import Loader from 'src/SharedComponents/Loader/Loader';
const NavbarContentWrapper:React.FC<{children:React.ReactNode}> = ({children}) => {
    const { loader } = useAuraSupportInfo();
    return  (
        <>
            <div className="content-wrapper">
                <div className="content-layout mt-5">
                    {loader && <Loader />}
                    {children}
                </div>
            </div>
        </>
    )
}
export default NavbarContentWrapper;