import { useMsal } from "@azure/msal-react";

const LogOut = () => {
    const { instance } = useMsal();
    return (
        <div style={{ textAlign: 'center' }}>
            <span onClick={() => { instance.logoutRedirect(); }} id="logout" style={{ color: '#A8927D', fontSize: '15px', fontWeight: '500', cursor: 'pointer' }}>
                Log Out
            </span>
        </div>
    )
}
export default LogOut;