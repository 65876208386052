import { useEffect } from "react";
import { useAuraSupportInfo } from "../../UseAuraInfoContext";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useGetPartnersInfo } from "./useGetPartnersInfo";

export const useGetPartnersInfoWrapper = () => {
    const { data, loading, error, getPartnersInfo } = useGetPartnersInfo()
    const { setInfobar, setLoader, reset } = useAuraSupportInfo();
    useEffect(() => {
        if (error) {
            logger.logError(`Failed to get all distributor details.`)
            setInfobar({message: error, messageType: InfoMessageType.error});
        }
    }, [error, setInfobar])
    
    useEffect(()=> {
        setLoader(loading);
    }, [loading, setLoader])
    useEffect(() => {
        reset();
    },[reset])
    return {data, getPartnersInfo}
}

