import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { usePostRequest } from "../../ApiWrappers/usePostRequest";


export const useInvitePartner = () => {
    const { postRequest, reqestError, reqestLoading, reqestResponse } = usePostRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);

    const invitePartnerUser = useCallback((email:string, partnerId:string) => {
        const requestUrl = `${baseUrl}api/v1/distributor/${partnerId}/invite-user`;
        logger.logInformation(`Request to invite partner user with email ${email} and partner id ${partnerId} was made`)
        var partnerReq = {'email': email};
        postRequest<{email:string}>(partnerReq,requestUrl)
    },[baseUrl, postRequest]) 

    return { invitePartnerUser, reqestResponse, reqestLoading, reqestError};
};
