import { INavbarItem } from "src/SharedComponents/Navbar/SubNavBar/SubNavBar";


export const TopItems: INavbarItem[] =  [
    
    {
        id:'contact',
        name:'Contact',
        hasChildren: false,
        routePath:'/contact',
        testid:'contact'
    },
    {
        id:'devices',
        name:'Devices',
        hasChildren: false,
        routePath:'/devices',
        testid:'devices'
    },
    {
        id:'partners',
        name:'Partners',
        hasChildren: false,
        routePath:'/partners',
        testid:'partners'
    },
    {
        id:'users',
        name:'Users',
        hasChildren: false,
        routePath:'/users',
        testid:'users'
    }
]
  