import { useCallback, useEffect, useRef, useState } from "react";
import Button from "src/SharedComponents/Button/Button";
import Dialog from "src/SharedComponents/Dialog/Dialog";
import EmailInput from "src/SharedComponents/EmailInput/EmailInput";
import ErrorMessage from "src/SharedComponents/ErrorMessage/ErrorMessage";
import InputBoxWithLabel from "src/SharedComponents/InputBoxWithLabel/InputBoxWithLabel";
import { ICreatePatnerRequest } from "src/Utils/Hooks/GetPartnersInfo/CreatePartner/useCreatePartner";
import { useCreatePartnerAndInvitePartnerUser } from "src/Utils/Hooks/GetPartnersInfo/useCreatePartnerAndInviteUser";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { isValidEmail } from "src/Utils/ValidationUtils/ValidationUtils";

interface ICreatePartnerParams {
    onClose: () => void;
    updatePartners: () => void;
  }

const CreatePartner : React.FC<ICreatePartnerParams> = ({ updatePartners, onClose }) => {
    const {createPartnerAndInviteUser, reqestResponse} = useCreatePartnerAndInvitePartnerUser()
    const [display, setdisplay] = useState('block');
    let createPartnerReq: ICreatePatnerRequest = {
        name: '',
        partnerAdminemail: ''
      }
      const createPartnerRequest = useRef<ICreatePatnerRequest>(createPartnerReq);

      const [validationErrors, setValidationErrors] = useState({
        name: '',
        partnerAdminemail: ''
      });

      useEffect(() => {
        if(reqestResponse) {
            updatePartners()
            onClose()
        }
      },[reqestResponse, updatePartners,onClose])

      const isValidRequest = useCallback(() => {
        let isValid = true;
        const errors: any = {};
        if (createPartnerRequest.current.name === '') {
            errors.name = 'Name is required';
            isValid = false;
          }

        if (createPartnerRequest.current.partnerAdminemail === '') {
            errors.partnerUserEmail = 'Email address is required';
            isValid = false;
          }
      
          else if (!isValidEmail(createPartnerRequest.current.partnerAdminemail)) {
            errors.partnerUserEmail = '"Invalid email address"';
            isValid = false;
          }
          setValidationErrors(errors);

          return isValid;
      },[])

      const createPartner = () => {
        if (isValidRequest()) {
           logger.logInformation(`The request has been submitted to create Partner with name: ${createPartnerRequest.current.name}, partner user email: ${createPartnerRequest.current.partnerAdminemail}`);
           createPartnerAndInviteUser(createPartnerRequest.current.name, createPartnerRequest.current.partnerAdminemail);
           setdisplay('none');
        }
      }

      
    return (
        <>
            <div style={{ display: display }}>
                <Dialog
                    title="Create new partner"
                    headerAlign='left'
                    submitText=""
                    onClose={() => onClose()}
                    onSubmit={() => { }}
                    paddingTop='180px'
                    disableSubmit={true}
                    hideSubmit={true}
                >
                    <div style={{ paddingRight: '26px', width: '382px' }}>
                        <div>
                            <InputBoxWithLabel
                                onChange={(e) => { createPartnerRequest.current.name = e }}
                                label="Partner Name" />
                            <ErrorMessage message={validationErrors.name}></ErrorMessage>
                        </div>

                        <div className="mt-3">
                            <div className="label">
                                Admin Email Address
                            </div>
                            <div className="mt-2">
                                <EmailInput
                                    onChange={(e) => createPartnerRequest.current.partnerAdminemail = e} />
                            </div>
                            <ErrorMessage message={validationErrors.partnerAdminemail}></ErrorMessage>
                        </div>
                        <div className="mt-3" style={{ position: 'relative'}}>
                            <Button
                                testId='ok'
                                onClick={() => { createPartner()}}
                                text="Create & Send Invitation"
                                width="354px"
                            />
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    )
}
export default CreatePartner;