import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { usePostRequest } from "../../ApiWrappers/usePostRequest";
import { logger } from "src/Utils/LoggerService/LoggerService";

export interface ICreatePatnerRequest {
    name: string,
    partnerAdminemail:string
}


export const useCreatePartner = () => {
    const { postRequest, reqestError, reqestLoading, reqestResponse } = usePostRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);

    const createNewPartner = useCallback((partnerName: string) => {
        const requestUrl = `${baseUrl}api/v1/distributor`;
        logger.logInformation(`Request to create partner name: ${partnerName} was made`)
        var partnerReq = {'name': partnerName};
        postRequest<{name:string}>(partnerReq, requestUrl);
    }, [baseUrl, postRequest]);

    return { createNewPartner, reqestResponse, reqestLoading, reqestError};
};