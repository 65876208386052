import { useEffect, useState } from "react";
import { useAuraSupportInfo } from "../../UseAuraInfoContext";
import { useCreatePartner } from "./useCreatePartner"
import { logger } from "src/Utils/LoggerService/LoggerService";
export interface INewPartnerInfo {
    distributorId:string,
    name: string,
    email: string,
    contactNumber: string,
    streetAddress:string,
    city:string,
    country: string,
    zipCode:string,
    isKol: true,
    users: []
}
export const useCreatePartnerWrapper = () => {
    const { createNewPartner, reqestResponse, reqestLoading, reqestError } = useCreatePartner();
    const {setInfobar, setLoader} = useAuraSupportInfo()
    const [createPartnerError, setCreatePartnerError] = useState<string>('')
    const [partnerInfo, setPartnerInfo] = useState<INewPartnerInfo>()
    useEffect(() => {
        setLoader(true)
     },[reqestLoading, setLoader])

     useEffect(() => {
        if(reqestError !== '') {
            setCreatePartnerError(reqestError)
           logger.logError(reqestError);
        }
    }, [reqestError, setInfobar])
    useEffect(() => {
        if(reqestResponse) {
           logger.logInformation(reqestResponse.data);
           setPartnerInfo(reqestResponse.data);
        }
    }, [reqestResponse, setInfobar])

    return {partnerInfo, createNewPartner, createPartnerError}
}