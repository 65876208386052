import './App.css';
import { MsalProvider } from '@azure/msal-react';
import AppLayout from './Components/AppLayout/AppLayout';
import { AuthConfig } from './AuthConfig';
import TelemetryProvider from './Utils/LoggerService/LoggerProvider';

function App() {
  return (
    <div >
      <TelemetryProvider>
        <MsalProvider instance={AuthConfig.getMsalInstance()}>
            <AppLayout />
        </MsalProvider>
        </TelemetryProvider>
    </div>
  );
}

export default App;
