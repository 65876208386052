import { createContext, useCallback, useContext, useEffect, useRef } from "react";
import { InfoMessageType } from "src/SharedComponents/InfoBar/InfoBar";
export type InfobarDetails = {
    message:string,
    messageType:InfoMessageType
}
export type InfoContextType = {
    show: boolean;
    infoMessageDetails:InfobarDetails;
    loader:boolean;
    setLoader: (show:boolean) => void,
    setShow: (show:boolean) => void
    setInfoMessageDetails: (infoMessageDetails:InfobarDetails) => void,
}
export const InfoContextDefaultValues : InfoContextType = {
    show:false,
    setShow:() =>{},
    loader:false,
    setLoader:() => {},
    infoMessageDetails:{message:'', messageType:InfoMessageType.none},
    setInfoMessageDetails:()=> {},
}

export const InfoContext = createContext<InfoContextType>(InfoContextDefaultValues);
export const useAuraSupport = () => useContext(InfoContext);
export const useAuraSupportInfo = () =>
{
    const {show, setShow, infoMessageDetails, setInfoMessageDetails, loader, setLoader} = useAuraSupport();
    const infoTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const setInfobar = useCallback((details:InfobarDetails) => {
        setShow(true);
        setLoader(false);
        setInfoMessageDetails(details);
    },[setShow, setInfoMessageDetails, setLoader])
    useEffect(() => {
        if (show) {
            if (infoTimeoutRef.current) {
                clearTimeout(infoTimeoutRef.current);
            }
            const timeoutId = setTimeout(() => {
                setShow(false);
            }, 10000);
            infoTimeoutRef.current = timeoutId;
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [show, setShow]);
    const reset = useCallback(() => {
        setShow(false);
        setInfoMessageDetails({message:'', messageType:InfoMessageType.none})
        setLoader(false);
    },[setShow, setLoader, setInfoMessageDetails])

    return {show, setShow, infoMessageDetails, setInfobar, loader, setLoader, reset}
}