import GridView from "src/SharedComponents/GridView/GridView";
import LogoText from "src/SharedComponents/LogoText/LogoText";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import mapPinLogo from 'src/images/map-pin.svg';

const Contact: React.FC = () => {

    return (
        <>
           <GridView title="Hexagon Aura Reality AG">
                <div className="d-flex flex-column">
                    <LogoText logoSrc={mailLogo} text='office@aurareality.com' />
                    <LogoText logoSrc={phoneLogo} text='+41 71 727 31 31' />
                    <LogoText logoSrc={mapPinLogo} text='Heinrich-Wild-Strasse 201, 9435 Heerbrugg, Switzerland' />
                </div>
                <div>
                    <div className="row mt-3">
                            <div className="mb-2 col-9">
                                <b>Support Contact</b>
                            </div>
                    </div>
                    <LogoText logoSrc={mailLogo} text='support@aurareality.com' />
                </div>
            </GridView>
        </>
    )
}
export default Contact;