import React, { useRef } from "react";
import Modal from "src/Utils/CssModules/modal.module.css";
import ProfileIcon from "../../SharedComponents/ProfileIcon/ProfileIcon";
import "./Profile.css";
import LogOut from "src/Components/Authentication/Logout";
import useOutsideClick from "src/Utils/Hooks/UseOutSideClick/UseOutSideClick";
export interface IProfileParams {
  firstName: string;
  lastName: string;
  onClose: () => void;
}

const Profile: React.FC<IProfileParams> = ({
  firstName,
  lastName,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const userFirstName = useRef<string>(firstName);
  const userLastName = useRef<string>(lastName);
  useOutsideClick(ref, () => onClose())
  return (
    <>
        <div className="row">
          <div
            id="myModal"
            className={`${Modal.modal}`}
            style={{
              display: "flex",
              justifyContent: "end",
              zIndex: 1002,
              paddingTop: 0,
            }}
          >
            <div className={`${Modal.content} profile-content`} ref={ref}>
              <div className={`${Modal.body}`} style={{ display: "contents" }} >
                <div className="row">
                  <ProfileIcon firstName={userFirstName.current} lastName={userLastName.current} />
                </div>
                <div className="row profile-name">
                  <span>
                    {userFirstName.current} {userLastName.current}
                  </span>
                </div>
                <div className="row profile-logout">
                  <LogOut />
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};
export default Profile;
