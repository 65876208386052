import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import NavbarContentWrapper from "../LayoutWrapper/NavbarContentWrapper";
import NavbarInfoContainer from "../NavbarInfoContainer/NavbarInfoContainer";

const MainContent : React.FC = () => {

    return (
        <>
                <div style={{marginBottom: '40px'}}>
                    <Header />
                    <NavbarInfoContainer />
                </div>
                <NavbarContentWrapper >
                    <Outlet />
                </NavbarContentWrapper>
        </>
    )
}
export default MainContent;

